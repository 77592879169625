<template>
    <div>
        <download-csv
            v-if="$store.state.permissions.includes('company-csv')"
            class="btn btn-info pull-right hand"
            :data="csvData"
            name="CompaniesData.csv"
        >
            <i class="fa fa-download" /> CSV
        </download-csv>
        <b-button
            class="btn btn-primary pull-right hand"
            @click="archivedCompany"
            style="margin-right:9px;"
            v-if="
        selected.length > 0 &&
          $store.state.permissions.includes('company-archive')
      "
        >Archived</b-button
        >
        <!-- <b-button
            class="btn btn-info pull-right hand"
            @click="subScriptionCompany"
            style="margin-right:9px;"
            v-if="$store.state.permissions.includes('company-csv')"
        >
            <i class="fa fa-refresh" /> SubScription Status</b-button
        > -->
        <b-row class="ml-1">
            <h3>All Companies</h3>
        </b-row>
        <div class="mt-2 d-flex flex-wrap justify-content-between">
            <div class="ml-2 " >
                <label for="" class="mt-0 d-block font-weight-bold font-12"
                >Records Per Page
                </label>
                <b-form-select class="hand width-select" :options="pageOptions" v-model="perPage" />
            </div>
            <div class="ml-2" >
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12"
                    >Filter By Company Status</label
                    >
                    <select
                        v-b-tooltip.hover
                        v-model="searchData.company_status"
                        id="filter"
                        name="filter"
                        class="form-control select-single hand"
                        data-placement="Date"
                    >
                        <option value="unarchived">UnArchived</option>
                        <option value="archived">Archived</option>
                    </select>
                </div>
            </div>
            <div class="ml-2" >
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12"
                    >Filter By Subscription Status</label
                    >
                    <select
                        v-b-tooltip.hover
                        v-model="searchData.subscription_status"
                        id="filter"
                        name="filter"
                        class="form-control select-single hand"
                        data-placement="Date"
                    >
                        <option value="all">All</option>1 
                        <option value="active">Active</option>1
                        <option value="canceled">Cancelled</option>
                        <option value="onTrial" selected>On Trial</option>
                        <option value="expired">Expired</option>
                    </select>
                </div>
            </div>
            <div class="ml-2" >
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12"
                    >Date Range
                    </label>
                    <div id="daterange" class="daterange" style="width:300px;">
                            <i class="fa fa-calendar place float-left"></i>
                        <span class="font ml-2 float-left"></span>
                    </div>
                </div>
            </div>

            <!--end-->
            <!--            <b-col md="3">-->
            <!--                <label class="mt-0 font-weight-bold font-12">Search</label>-->
            <!--                <b-input-group>-->
            <!--                    <b-form-input v-model="search" placeholder="Search with company name"/>-->
            <!--                    <b-input-group-append>-->
            <!--                        <b-btn class="hand" :disabled="!search" @click="search = ''">Clear</b-btn>-->
            <!--                    </b-input-group-append>-->
            <!--                </b-input-group>-->
            <!--            </b-col>-->
        </div>

        <b-row class="mt-4">
            <b-col>
                <b-table
                    small
                    id="companies_table"
                    responsive = "true"
                    :busy.sync="isBusy"
                    show-empty
                    empty-text="No Companies Found"
                    striped
                    bordered
                    hover
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :filter="search"
                    @filtered="onFiltered"
                    class="break"
                    style="overflow:scroll!important"
                >
                    <template  slot="thead-top"  slot-scope="{ fields }">
                      <tr>
                       <th v-for="field in fields" align="center" class="thead" :key="field.key">
                        <div class="display-flex pb-1 ">
                        <div class="ml-1">
                            {{ field.label }}
                        </div>
                        <div class="float-right margin-top">
                        <div class="display-sort " :class="{ opacity : columnOrder === 'asc' && columnName == field.key}">
                            <i class="fa fa-chevron-up sorting-cursor"
                               :class="{'display-none' : field.key == 'checkbox' || field.key == 'action' }"
                                @click="sortData(field.key,'asc')"></i>
                            </div>
                            <div class="display-sort " :class="{ opacity : columnOrder === 'desc' && columnName == field.key}">
                            <i class="fa fa-chevron-down  sorting-cursor"
                               :class="{'display-none' : field.key == 'checkbox' || field.key == 'action' }"
                                @click="sortData(field.key,'desc')"></i>
                                
                        </div>
                        </div>
                        </div>
                       <!-- <div>
                           <span>{{ field.label }}</span>
                           <span class="float-right">
                           <span class=" " :class="{ opacity : columnOrder === 'asc' && columnName == field.key}">
                            <i class="fa fa-sort-asc fa-2x "
                               :class="{'display-none' : field.key == 'checkbox' || field.key == 'action' }"
                                @click="sortData(field.key,'asc')"></i>
                            </span>
                            <span class=" " :class="{ opacity : columnOrder === 'desc' && columnName == field.key}">
                            <i class="fa fa-sort-desc fa-2x "
                               :class="{'display-none' : field.key == 'checkbox' || field.key == 'action' }"
                                @click="sortData(field.key,'desc')"></i>
                                
                        </span>
                        </span> 
                       </div> -->
                       </th>
                      </tr>
                    </template>
                    <template slot="top-row" slot-scope="{ fields }">
                        <td v-for="field in fields" :key="field.key">
                            <input
                                v-model="searchData.name"
                                placeholder="Company Name"
                                v-if="field.key == 'name'"
                                class="input-search field"
                            />
                            <input
                                v-model="searchData.owner"
                                placeholder="Owner Name"
                                v-if="field.key == 'owner'"
                                class="input-search field"
                            />
                            <input
                                v-model="searchData.owner_email"
                                placeholder="Owner Email"
                                v-if="field.key == 'owner_email'"
                                class="input-search field"
                            />
                            <div v-if="field.key == 'payment_type'" class="ml-1">
                            <b-dropdown id="dropdown" text="    Payment type    " class="payment-type-dropdown">
                                <b-dropdown-item @click="searchData.payment_type = null">All</b-dropdown-item>
                                <b-dropdown-item @click="searchData.payment_type = 'stripe'">Stripe</b-dropdown-item>
                                <b-dropdown-item @click="searchData.payment_type = 'paypal'">Paypal</b-dropdown-item>
                            </b-dropdown>
                            </div>
                            <!-- <input
                                v-model="searchData.payment_type"
                                placeholder="Payment type"
                                v-if="field.key == 'payment_type'"
                                class="input-search field"
                            /> -->

                        </td>
                    </template>
                    <template v-slot:cell(checkbox)="data">
                        <label
                            class="form-checkbox"
                            v-if="data.item.company_status === 'Activated'"
                        >
                            <input
                            class="ml-1 mt-3"
                                type="checkbox"
                                :value="data.item.hash_id"
                                v-model="selected"
                            />
                            <i class="form-icon ml-3"></i>
                        </label>
                    </template>
                    <template v-slot:cell(country)="data">
                        <div v-b-tooltip.hover :title="data.item.ip_address" class="country-data"  :class="showCountry(data.item)"></div>

                        <!-- <img
                          v-if="data.value"
                          :src="'https://api.stafftimerapp.com/storage/' + data.value"
                          width="40px"
                          height="40px"
                          :id="data.value"
                        />
                        <img
                          v-else
                          src="https://via.placeholder.com/40/10659C/FFFFFF?text=STA"
                          :id="data.item.hash_id"
                          width="40px"
                          height="40px"
                        />
                        <b-popover
                          v-if="data.value"
                          :target="data.value"
                          :key="data.value"
                          triggers="hover"
                        >
                          <img
                            v-if="data.value"
                            :src="'https://api.stafftimerapp.com/storage/' + data.value"
                            class="image"
                          />
                        </b-popover>
                        <b-popover
                          v-if="data.item.hash_id"
                          :target="data.item.hash_id"
                          :key="data.item.hash_id"
                          triggers="hover"
                        >
                          <img
                            src="https://via.placeholder.com/200/10659C/FFFFFF?text=STA"
                            class="image"
                          />
                        </b-popover> -->
                    
                    </template>
                    <template v-slot:cell(owner)="cell">
                        <!-- We use click.stop here to prevent a 'row-clicked' event from also happening -->
                        {{ cell.item.owner }}
                        <b-btn
                            v-if="
                $store.state.permissions.includes('company-user-impersonate')
              "
                            size="sm"
                            class="float-right"
                            v-b-tooltip.hover
                            title="Impersonate this User"
                            @click.stop="impersonate(cell.item.owner_encrypted_id)"
                        ><i class="fa fa-bug"></i
                        ></b-btn>
                    </template>
                    <template v-slot:cell(subscription_status)="cell"
                    >{{ convertToTitleCase(cell.item.subscription_status) }}
                    </template>
                    <template v-slot:cell(last_screenshot_time)="data">
                        <div v-b-tooltip.hover :title="showDate(data)">
                            {{getDate(data)}}
                        </div>
                    </template>
                    <template v-slot:cell(action)="cell">
                        <i
                            v-if="$store.state.permissions.includes('company-update')"
                            @click="editModel(cell.item)"
                            class="fa fa-pencil ml-1 hand"
                            title="Edit company trial and charges"
                            v-b-tooltip.hover
                        />
                        <i

                            @click="updateSubscriptionModel(cell.item)"
                            class="fa fa-edit ml-1 hand"
                            title="Update company subscription"
                            v-b-tooltip.hover
                            v-if="cell.item.subscription_status === 'active' && $store.state.permissions.includes('company-update')"
                        />
                        <i

                            @click="updateQuantityModel(cell.item)"
                            class="fa fa-plus ml-1 hand"
                            title="Increment/Decrement Quantity"
                            v-b-tooltip.hover
                            v-if="cell.item.subscription_status === 'active' && $store.state.permissions.includes('company-update')"
                        />
                        <!--                        <i v-if="$store.state.permissions.includes('company-delete')" @click="deleteCompany(cell.item)" aria-hidden="true" class="fa fa-trash ml-1 hand" title="Delete this company" v-b-tooltip.hover/>-->
                        <i
                            v-if="$store.state.permissions.includes('company-update')"
                            @click="editModelQuantity(cell.item)"
                            class="fa fa-pencil-square-o hand edit"
                            title="Edit company projects and tasks , members"
                            v-b-tooltip.hover
                        />
                        <span
                            v-if="cell.item.company_status === 'Activated'"
                            class="archive"
                        >
              <i
                  v-if="$store.state.permissions.includes('company-archive')"
                  @click="archiveCompany(cell.item.hash_id)"
                  title="Archive"
                  v-b-tooltip.hover
              ><i class="fa fa-archive hand"
              /></i>
            </span>
                        <span
                            v-else-if="cell.item.company_status === 'Archived'"
                            class="archive"
                        >
              <i
                  v-if="$store.state.permissions.includes('company-restore')"
                  @click="restoreCompany(cell.item.hash_id)"
                  size="sm"
                  title="Restore"
                  v-b-tooltip.hover
              ><i class="fa fa-window-restore hand"
              /></i>
            </span>

                        <span style="padding-left: 3px" v-if="cell.item.active_status == true">
              <i
                  v-if="$store.state.permissions.includes('company-update')"
                  @click="suspendCompany(cell.item.hash_id,cell.item.active_status)"
                  title="Active"
                  v-b-tooltip.hover
                  class="fa fa-unlock-alt hand"
              >
              </i>
            </span>
                        <span  style="padding-left: 3px" v-if="cell.item.active_status == false">
            <i
                v-if="$store.state.permissions.includes('company-update')"
                @click="unsuspendCompany(cell.item.hash_id,cell.item.active_status)"
                title="Deactive"
                v-b-tooltip.hover
                class="fa fa-lock hand"
            ></i>
          </span>


                    </template>                  
                </b-table>
                <b-row>
                    <b-col md="12" class="my-1">
                        <b-pagination
                            align="right"
                            aria-controls="companies_table"
                            :total-rows="pagination.total"
                            :per-page="pagination.per_page"
                            v-model="currentPage"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <CompanyUpdate />
        <CompanyUpdateQuantity @updateCompany="updateCompanyQty" />
        <CompanySubscriptionUpdate />
        <UpdateQuantity />
    </div>
</template>

<script>
    import moment from "moment";
    import $ from "jquery";
    import { mapState } from "vuex";
    import "daterangepicker/daterangepicker.js";
    import "daterangepicker/daterangepicker.css";
    import _ from "lodash";
    import countries  from "@/assets/js/countries";

    require("@/assets/css/flags.css");

    export default {
        name: "Companies",
        components: {
            CompanyUpdate: () => import("@/components/companies/CompanyUpdate"),
            CompanySubscriptionUpdate: () =>
                import("@/components/companies/CompanySubscriptionUpdate"),
            CompanyUpdateQuantity: () =>
                import("@/components/companies/CompanyUpdateQuantity"),
            UpdateQuantity: () => import("@/components/companies/UpdateQuantity")
        },
        data() {
            return {
                desc_order: false,
                active_status: false,
                // start: moment().subtract(29, "days"),
                showCountryCode:'',
                countries : countries,
                start: moment("2017-01-01"),
                company_value: [],
                searchData: {
                    name: null,
                    owner: null,
                    owner_email: null,
                    payment_type: null,
                    subscription_status: "all",
                    company_status: "unarchived",
                    sort:{
                        column_name:"created_at",
                        sort_by:"desc"
                    }
                },
                end: moment(),
                // start_date : moment().subtract(29, "days").format('YYYY-MM-DD'),
                start_date : '2017-01-01',
                end_date   : moment().format('YYYY-MM-DD'),
                fields: [
                    {
                        key: "checkbox",
                        label: "Check",
                        thStyle: {
                            width: "160px !important",
                        },
                    },
                    {
                        key: "country",
                        label: "Country",
                        thStyle: {
                            width: "160px",
                            
                        }
                    },
                    {
                        key: "name",
                        thStyle: {
                            width: "240px"
                        },

                    },
                    {
                        key: "owner",
                        thStyle: {
                            width: "130px"
                        }
                    },
                    {
                        key: "owner_email",
                        thStyle: {
                            width: "160px"
                        }
                    },
                    {
                        key: "subscription_status",
                        thStyle: {
                            width: "91px"
                        }
                    },
                    {
                        key: "payment_type",
                        thStyle: {
                            width: "91px"
                        }
                    },
                    {
                        key: "projects_count",
                        label: "Projects",
                        thStyle: {
                            width: "72px"
                        }
                    },
                    {
                        key: "employees_count",
                        label: "Employees",
                        thStyle: {
                            width: "100px"
                        }
                    },
                    {
                        key: "screenshots_count",
                        label: "Screenshots",
                        thStyle: {
                            width: "100px"
                        }
                    },
                    {
                        key: "created_at",
                        label: "Created At",
                        thStyle: {
                            width: "130px"
                        }
                    },
                    {
                        key: "last_screenshot_time",
                        label: "Last Screenshot Time",
                        thStyle: {
                            width: "130px"
                        }
                    },
                    {
                        key: "last_payment_date",
                        thStyle: {
                            width: "130px"
                        }
                    },
                    {
                        key: "last_payment_amount",
                        thStyle: {
                            width: "120px"
                        }
                    },
                    {
                        key: "action",
                        label: "Action",
                        thStyle: {
                            width: "76px"
                        }
                    }
                ],
                
                currentPage: 1,
                perPage: 100,
                pageOptions: [10, 25, 50, 100],
                search: null,
                filter: "all",
                totalRows: 0,
                selected: []
            };
        },
        computed: {
            ...mapState(["companies", "pagination"]),
            columnOrder(){
                return this.searchData.sort.sort_by
                },
            columnName(){
                return this.searchData.sort.column_name
                },
            items() {
                return this.filter === "all"
                    ? this.companies
                    : this.companies.filter(
                        company => company.subscription_status === this.filter
                    );
            },



            pagination() {
                return this.$store.state.pagination;
            },
            csvData() {
                return this.items.map((item, index) => ({
                    Index: index + 1,
                    Name: item.name,
                    "Owner Name": item.owner,
                    "Owner Email": item.owner_email,
                    Projects: item.projects_count,
                    Employees: item.employees_count,
                    "Created At": item.created_at,
                    "Screen Shots": item.screenshots_count,
                    "Last Screen Shot Time": item.last_screenshot_time,
                    "Company Status": item.company_status,
                    "Subscription Status": item.subscription_status,
                    "Last Payment Date": item.last_payment_date
                }));
            },
            isBusy() {
                return this.$store.state.isBusy;
            },

        },

        mounted() {
            this.dateRangeStart();
            this.getDashboardData();
            this.totalRows = this.items.length;
            fetch("https://jsonplaceholder.typicode.com/users")
                .then(resp => resp.json())
                .then(json => (this.users = json));
        },
        watch: {
            currentPage() {
                this.getDashboardData();
            },

            "searchData.name": {
                handler: _.debounce(function(val) {
                    this.getDashboardData();
                }, 1000),
                deep: true
            },

            "searchData.owner": {
                handler: _.debounce(function() {
                    this.getDashboardData();
                }, 1000),
                deep: true
            },

            "searchData.owner_email": {
                handler: _.debounce(function() {
                    this.getDashboardData();
                }, 1000),
                deep: true
            },

            "searchData.subscription_status": {
                handler: _.debounce(function() {
                    this.getDashboardData();
                }, 10),
                deep: true
            },
            "searchData.payment_type": {
                handler: _.debounce(function() {
                    this.getDashboardData();
                }, 10),
                deep: true
            },

            "searchData.company_status": {
                handler: _.debounce(function() {
                    this.getDashboardData();
                }, 10),
                deep: true
            },

            perPage() {
                this.getDashboardData();
            }
        },
        methods: {
            sortData(column_name, order_by) {
               this.searchData.sort.column_name = column_name
               this.searchData.sort.sort_by = order_by
               this.getDashboardData()
             },
            getDate(data)
            {

                if(data.value)
                {

                    const dateTimeAgo = moment(data.value).fromNow();
                    return dateTimeAgo;
                }

            },
            showDate(data) {
                let dateshow =  moment(data.value).format('MMM Do, YYYY');
                return dateshow
            },
            showCountry(d)
            {
                let count = this.countries.find(k =>k.name === d.country)
                if(count)
                {
                    return 'flag flag-' + count.code.toLowerCase();

                }

            },
            subScriptionCompany() {
                this.$store
                    .dispatch("subScriptionStatus")
                    .then(response => {
                        this.$store.commit("toggle_loader", false);
                    })
                    .catch(() => {
                        this.$store.commit("toggle_loader", false);
                    });
            },
            cvsCompany() {
                // this.$store.commit("toggle_loader", true);
                this.$store.commit("toggle_loader", true);
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                    per_page: this.perPage,
                    page: this.currentPage,
                    search: this.searchData
                };
                this.$store
                    .dispatch("csvCompany", data)
                    .then(response => {
                        // let blob = new Blob([response.data], {type: 'vnd.ms-excel;charset=utf-8'});
                        // let link = document.createElement('a');
                        // link.href = window.URL.createObjectURL(blob);
                        // link.download = 'export.xlsx';
                        // link.click();
                        this.$store.commit("toggle_loader", false);
                    })
                    .catch(error => {
                        this.$store.commit("toggle_loader", false);
                    });
            },

            archivedCompany() {
                let app = this;
                app.$store.commit("toggle_loader", true);
                this.selected.forEach(function(hashId) {
                    let data = {
                        company_id: hashId
                    };
                    app.$store
                        .dispatch("archiveCompanyAdmin", data)
                        .then(response => {
                            app.selected.shift();
                            if (app.selected < 1) {
                                app.getDashboardData();
                            }
                            app.$store.commit("toggle_loader", false);
                        })
                        .catch(error => {
                            app.selected.shift();
                            if (app.selected < 1) {
                                app.getDashboardData();
                            }
                            app.$store.commit("toggle_loader", false);
                        });
                });
            },

            editModelQuantity(company_quantity) {
                this.$root.$emit("bv::show::modal", "updateModelQty");
                this.$root.$emit("selected_company_quantity", company_quantity);
            },

            updateCompanyQty(value) {
                if (value === true) {
                    this.getDashboardData();
                }
            },

            editModel(company) {
                this.$root.$emit("bv::show::modal", "updateModel");
                this.$root.$emit("selected_company", company);
            },

            updateSubscriptionModel(company) {
                this.$root.$emit("bv::show::modal", "updateSubscriptionModel");
                this.$root.$emit("selected_company", company);
            },

            updateQuantityModel(company) {
                this.$root.$emit("bv::show::modal", "updateQuantityModel");
                this.$root.$emit("selected_company", company);
            },

            dateRangeStart() {
                if (this.start.format("DD/MM/YYYY") == "01/01/2017") {
                    $("#daterange span").html("All time");
                } else {
                    $("#daterange span").html(
                        this.start.format("MMM D, YYYY") +
                        " - " +
                        this.end.format("MMM D, YYYY")
                    );
                }
                // $(".daterange span").html(
                //   this.start.format("MMM D, YYYY") +
                //     " - " +
                //     this.end.format("MMM D, YYYY")
                // );
                $(".daterange").daterangepicker(
                    {
                        startDate: this.start,
                        endDate: this.end,
                        opens: "left",
                        ranges: {
                            "Last 30 Days": [moment().subtract(29, "days"), moment()],
                            Today: [moment(), moment()],
                            Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days")
                            ],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment()
                                    .subtract(1, "month")
                                    .startOf("month"),
                                moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                            ],
                            "Last Year": [moment().subtract(1, "year"), moment()],
                            "All time": [moment("2017-01-01"), moment()]
                        }
                    },
                    this.dateRangeChange
                );
            },

            dateRangeChange(start, end) {
                $(".daterange span")
                    .html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
                    .addClass("pl-2 pr-1");
                this.start_date = start.format("YYYY-MM-DD");
                this.end_date = end.format("YYYY-MM-DD");
                this.getDashboardData();
            },

            getDashboardData() {
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                    per_page: this.perPage,
                    page: this.currentPage,
                    search: this.searchData
                };
                this.$store.dispatch("getCompanies", data).then(() => {
                    this.$store.commit("toggle_loader", false);
                });
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            convertToTitleCase(phrase) {
                if(phrase){
                    return phrase
                        .replace(/[A-Z]/g, " $&")
                        .split(" ")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ");
                }
            },

            impersonate(id) {
                let url = "https://backoffice.stafftimerapp.com/user-login/" + id;
                let win = window.open(url, "_blank");
                win.focus();
            },



            archiveCompany: function(hash_id) {
                let buttons = {
                    cancel : true,
                    confirm : {
                        text : "Archive",
                        value : "delete",
                        dangerMode : true,
                        closeModal : true
                    }
                };
                swal ( {
                    title : "",
                    text : "Are you sure to archive this company?",
                    icon : "warning",
                    buttons : buttons,
                    dangerMode : true
                }).then ( willDelete => {
                    if ( willDelete === "delete" )
                    {
                        this.$store.commit("toggle_loader", true);
                        let data = {
                            company_id: hash_id
                        };
                        this.$store
                            .dispatch("archiveCompanyAdmin", data)
                            .then(response => {
                                this.getDashboardData();
                            })
                            .catch(error => {
                                this.$store.commit("toggle_loader", false);
                            });
                    }
                });
            },


            // archiveCompany: function(hash_id) {
            //   this.$store.commit("toggle_loader", true);
            //   let data = {
            //     company_id: hash_id
            //   };
            //   this.$store
            //     .dispatch("archiveCompanyAdmin", data)
            //     .then(response => {
            //       this.getDashboardData();
            //     })
            //     .catch(error => {
            //       this.$store.commit("toggle_loader", false);
            //     });
            // },

            restoreCompany: function(hash_id) {
                let buttons = {
                    cancel : true,
                    confirm : {
                        text : "Restore",
                        value : "delete",
                        dangerMode : true,
                        closeModal : true
                    }
                };
                swal ( {
                    title : "",
                    text : "Are you sure to restore this company?",
                    icon : "warning",
                    buttons : buttons,
                    dangerMode : true
                }).then ( willDelete => {
                    if ( willDelete === "delete" )
                    {
                        this.$store.commit("toggle_loader", true);
                        let data = {
                            company_id: hash_id
                        };
                        this.$store
                            .dispatch("restoreCompanyAdmin", data)
                            .then(response => {
                                this.getDashboardData();
                            })
                            .catch(error => {
                                this.$store.commit("toggle_loader", false);
                            });
                    }
                });
            },
            // restoreCompany: function(hash_id) {
            //   this.$store.commit("toggle_loader", true);
            //   let data = {
            //     company_id: hash_id
            //   };
            //   this.$store
            //     .dispatch("restoreCompanyAdmin", data)
            //     .then(response => {
            //       this.getDashboardData();
            //     })
            //     .catch(error => {
            //       this.$store.commit("toggle_loader", false);
            //     });
            // },

            suspendCompany : function(hash_id,status) {
                let buttons = {
                    cancel : true,
                    confirm : {
                        text : "Active",
                        value : "delete",
                        dangerMode : true,
                        closeModal : true
                    }
                };
                swal ( {
                    title : "",
                    text : "Are you sure to Active this company?",
                    icon : "warning",
                    buttons : buttons,
                    dangerMode : true
                }).then ( willDelete => {
                    if ( willDelete === "delete" )
                    {
                        if(status == true)
                        {
                            this.active_status = false
                        } else {
                            this.active_status = true
                        }
                        this.$store.commit("toggle_loader", true);
                        let data = {
                            company_id: hash_id,
                            active_status: this.active_status
                        };
                        this.$store
                            .dispatch("suspendCompanyAdmin", data)
                            .then(response => {
                                this.getDashboardData();
                            })
                            .catch(error => {
                                this.$store.commit("toggle_loader", false);
                            });
                    }
                });
            },
            unsuspendCompany : function(hash_id,status) {
                let buttons = {
                    cancel : true,
                    confirm : {
                        text : "Deactive",
                        value : "delete",
                        dangerMode : true,
                        closeModal : true
                    }
                };
                swal ( {
                    title : "",
                    text : "Are you sure to Deactive this company?",
                    icon : "warning",
                    buttons : buttons,
                    dangerMode : true
                }).then ( willDelete => {
                    if ( willDelete === "delete" )
                    {
                        if(status == true)
                        {
                            this.active_status = false
                        } else {
                            this.active_status = true
                        }
                        this.$store.commit("toggle_loader", true);
                        let data = {
                            company_id: hash_id,
                            active_status: this.active_status
                        };
                        this.$store
                            .dispatch("suspendCompanyAdmin", data)
                            .then(response => {
                                this.getDashboardData();
                            })
                            .catch(error => {
                                this.$store.commit("toggle_loader", false);
                            });
                    }
                });
            },



            deleteCompany(company) {
                if (
                    confirm(
                        "Are you really sure to delete this company? think twice before you proceed!"
                    )
                ) {
                    this.$store
                        .dispatch("deleteCompany", { company_id: company.hash_id })
                        .then(response => {
                            this.$store.commit("toggle_loader", false);
                            this.getDashboardData();
                            alert(response.data.error_msg);
                        })
                        .catch(() => {
                            this.$store.commit("toggle_loader", false);
                        });
                }
            }
        }
    };
</script>

<style>
.dropdown-menu{
    min-width: 120px;
    font-size: 15px;
}
.dropdown-item{
    padding: 0px 5px 0px 5px!important;
}
.payment-type-dropdown{
    height: 25px;
    border: 0px !important;
    background-color:white !important ;
    color: black!important;
    text-align: center;
    border-radius: 3px;

}
.btn-secondary{
    background-color:white !important;
    color: rgb(48, 47, 47)!important;
    font-size: 13px;
    /* width: 120px!important; */
    /* border-radius: 3px!important; */
    /* text-align: left!important; */
    padding: 2px 5px 2px 5px!important;
}
#companies_table thead tr:nth-child(2) {
  display: none !important;
}
#companies_table thead th{
    vertical-align: center!important;
}
/* #companies_table th.header-table {
    width: 120px !important;
} */
</style>
<style scoped>
.width-select{
    min-width: 130px!important;
}
.header{
    width: 150px !important;
}
.thead{
    height: 50px!important;
    width: 120px !important;
}
.display-flex{
    /* height: 50px!important; */
    width:120px !important;
}
.margin-top{
    margin-top: -26px;
}
.display-none{
    display: none!important;
}
.opacity {
  opacity: 1.5!important;
}
.sorting-cursor {
  cursor: pointer;
}
.display-sort {
  /* display: flex; */
  /* flex-direction: column; */
  opacity: 0.5;
  /* height: 8px!important; */
  /* float: right; */
}
.width{
    width: 100%;
}
.truncate {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
    .country-data{
        cursor: pointer;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .select-single {
        max-width: 23rem !important;
    }

    .image {
        max-height: 200px;
        min-height: 200px;
        max-width: 200px;
        min-width: 200px;
        border-radius: 3px;
    }

    .archive {
        margin-left: 7px;
    }
    .edit {
        padding-left: 4px !important;
    }
    .date_picker{
        /* margin-right: 100px !important; */
    }
    .field{
        width: 100%
    }
    .fa-unlock-alt:before {
        content: "\F13E";
        font-size: 14px;
    }
    .fa-lock:before {
        content: "\F023";
        font-size: 14px;
    }
    .swal-modal {
        width:300px;
    }
</style>
